import React, { useState, useEffect } from "react"
import "./Product.css"
import p1 from "../Images/Products/p1.jpeg"
import p2 from "../Images/Products/p2.jpeg"
import p3 from "../Images/Products/p3.jpeg"
import p4 from "../Images/Products/p4.jpeg"
import productVideo from "../videos/Curiokaards.mp4"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft, faChevronRight, faSquareCheck, faShoppingCart } from "@fortawesome/free-solid-svg-icons"

const Product = () => {
  const images = [p1, p2, p3, p4]
  const [currentImage, setCurrentImage] = useState(0)

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentImage((prev) => (prev === images.length - 1 ? 0 : prev + 1))
    }, 4000)

    return () => clearInterval(timer)
  }, [currentImage])

  const nextImage = () => {
    setCurrentImage((prev) => (prev === images.length - 1 ? 0 : prev + 1))
  }

  const prevImage = () => {
    setCurrentImage((prev) => (prev === 0 ? images.length - 1 : prev - 1))
  }

  return (
    <div className="product-sec">
      <div className="title">Our Products</div>
      <div className="curiokaards-container">
        <div className="product-media">
          <div className="image-carousel">
            <button className="carousel-btn prev" onClick={prevImage}>
              <FontAwesomeIcon icon={faChevronLeft} />
            </button>
            <img src={images[currentImage]} alt={`Product ${currentImage + 1}`} />
            <button className="carousel-btn next" onClick={nextImage}>
              <FontAwesomeIcon icon={faChevronRight} />
            </button>
          </div>
          
          <div className="video-container">
            <video 
              controls
              className="product-video"
              playsInline
            >
              <source src={productVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>

        <div className="product-info">
          <h2>CurioKAARDS</h2>
          <p>
            CurioKAARDS™ are engaging educational kits designed
            to spark curiosity in children. Each pack features
            curriculum-aligned gamified flashcards, where children
            earn points for correct answers and bonus points for
            exceptional responses. Perfect for individual or group
            activities, they can be used in classrooms or at home
            with parents. The kit includes a comprehensive
            instruction manual and a scorecard to track progress.
            The kit is designed for children aged 3 to 7 years,
            covering pre-primary to class 1 levels. Teachers and parents can adjust 
            the complexity level to suit children at different developmental stages.
          </p>
          <div className="price">
            Price Per Kit:<br/><br/>
            <div className="price-item">
              <FontAwesomeIcon icon={faSquareCheck} className="check-icon" /> English - ₹170
            </div>
            <div className="price-item">
              <FontAwesomeIcon icon={faSquareCheck} className="check-icon" /> Maths - ₹190
            </div>
          </div>
          <a 
            href="https://docs.google.com/forms/d/e/1FAIpQLSe-Yx3JcjL2nNI6vGDPa9oGa4KgIrcea1_m3Z1l_5COdn_Ryg/viewform?usp=sf_link"
            target="_blank"
            rel="noopener noreferrer"
            className="order-btn"
          >
            <span className="order-text">Order Now</span>
            <FontAwesomeIcon icon={faShoppingCart} className="cart-icon" />
          </a>
        </div>
      </div>
    </div>
  )
}

export default Product